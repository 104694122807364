<template>
  <div class="main">
    <div class="content">
      <div class="tabs">
        <p class="tab" :class="{'active':  activeIndex === '1'}" @click="getList('1')">新闻资讯</p>
        <p class="tab" :class="{'active':  activeIndex === '2'}" @click="getList('2')">通知公告</p>
      </div>
      <div class="news">
        <div class="news-li" v-for="(item, index) in newsList" :key="index">
          <div class="news-img">
            <img :src="item.image" alt="">
          </div>
          <div class="news-list">
            <ul class="news-list-ul">
              <li class="news-list-li">
                <p class="news-title">{{ item.name }}</p>
                <div class="news-time">
                  <span>{{ item.publish }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <pagination
        :num="num"
        :limit="limit"
        @getNew="getNew"
        v-if="num"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
export default {
  name: 'advicePage',
  components: {
    pagination,
  },
  data () {
    return {
      activeIndex: '1',
      newsList: [],
      num: 10, //数据总个数
      limit: 10, //一页显示几个
      nowPage: 1, //当前页码
    }
  },
  created () {
    this.ID = this.$route.query.ID
    this.getList('1')
  },
  methods: {
    toDetailPage () {
      this.$router.push({ name: 'newsDetailPage', query: { id: this.ID } })
    },
    getList(index) {
      this.activeIndex = index;
      this.newsList = [];
      this.$axios.post(`/v1/qn/newsList`, { area_id: this.ID, type: this.activeIndex }).then((res) => {
          if (res.code == 0) {
            this.newsList = res.data.data;
          }
        });
    },
    // 翻页
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getList();
    },
  }
}
</script>
<style lang="scss" scoped>
.main {
  background-color: #F7FAFF;
  .content {
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 90px;
    background-color: #FFFFFF;
    border-top: 10px solid #F5F6F7;
    .tabs {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .tab {
        height: 58px;
        padding: 0px 24px;
        background: #F0F6FF;
        border-radius: 5px 5px 5px 5px;
        font-weight: 500;
        font-size: 24px;
        color: #7EA0F0;
        line-height: 58px;
        text-align: center;
        margin: 0 25px;
      }
      .active {
        cursor: pointer;
        background: #366CEC;
        color: #FFFFFF;
      }
    }
    .news {
      box-sizing: border-box;
      margin: 0 auto;
      margin-bottom: 60px;
      padding: 0 20px;
      .news-li {
        border-bottom: 1px solid #E0E0E0;
        display: flex;
        align-items: center;
        padding: 32px 0;
        box-sizing: border-box;
        &:last-child {
          border-bottom: 1px solid #FFFFFF;
        }
      }
      .news-img {
        width: 275px;
        height: 168px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px 6px 6px 6px;
        }
      }
      .news-list {
        width: 392px;
        margin-left: 26px;
        .news-list-ul {
          .news-list-li {
            .news-title {
              font-weight: bold;
              font-size: 28px;
              color: #333333;
              line-height: 40px;
              text-align: left;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            .news-time {
              font-weight: 500;
              font-size: 24px;
              color: #666666;
              line-height: 34px;
              text-align: left;
              margin-top: 34px;
            }
          }
        }
      }
    }
  }
}
</style>